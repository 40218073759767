import React, { FC } from 'react'
import {
    TablesWidget11
} from '../../../../_metronic/partials/widgets'
import LoadingDataTables from '../../../../_metronic/partials/widgets/loader/LoadingDataTables'
// import {Helmet} from "react-helmet";

const InvoiceList: FC = () => {

    return (
        <>
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet> */}
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main" style={{ marginTop: "-26px", marginBottom: "-26px" }}>
                <div className="d-flex flex-column flex-column-fluid py-2">
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                        <div id="kt_app_content_container" className="app-container container-xxl"></div>
                        <div className="card card-flush">
                            <div className="card-header align-items-center py-3 gap-2 gap-md-12" style={{ marginBottom: "-51px" }}>
                                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-semibold mb-15"
                                    role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link text-active-primary pb-2 active" data-bs-toggle="tab"
                                            href="#kt_ecommerce_settings_general" aria-selected="true" role="tab">
                                            <span className="svg-icon svg-icon-2 me-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Maintainance</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link text-active-primary pb-2" data-bs-toggle="tab" href="#kt_ecommerce_settings_store"
                                            aria-selected="false" role="tab" tabIndex={-1}>
                                            <span className="svg-icon svg-icon-2 me-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z"
                                                        fill="currentColor"></path>
                                                    <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z"
                                                        fill="currentColor"></path>
                                                    <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="currentColor">
                                                    </path>
                                                    <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z"
                                                        fill="currentColor"></path>
                                                    <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="currentColor"></path>
                                                    <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="currentColor"></path>
                                                    <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="currentColor"></path>
                                                    <path
                                                        d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            Water Fees
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                                            href="#kt_ecommerce_settings_localization" aria-selected="false" role="tab" tabIndex={-1}>
                                            <span className="svg-icon svg-icon-2 me-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3"
                                                        d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z"
                                                        fill="currentColor"></path>
                                                    <path
                                                        d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>Parking Fees
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                                            href="#kt_ecommerce_settings_customers" aria-selected="false" role="tab" tabIndex={-1}>
                                            <span className="svg-icon svg-icon-2 me-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                                                        fill="currentColor"></path>
                                                    <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                    <path
                                                        d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                                                        fill="currentColor"></path>
                                                    <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"></rect>
                                                </svg>
                                            </span>Donation
                                        </a>
                                    </li>
                                </ul>
                                <div className="" style={{ marginTop: "-25px" }}>

                                    <div className="d-flex flex-column mb-8 fv-row">
                                        <div className="d-flex flex-stack gap-5 mb-3">
                                            <div className="slider">
                                                <input type="checkbox" name="slider" className="slider-checkbox" id="sliderSwitch" />
                                                <label className="slider-label" htmlFor="sliderSwitch">
                                                    <span className="slider-inner"></span>
                                                    <span className="slider-circle"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-header align-items-center gap-2 gap-md-5">
                                <div className="card-title gap-5">
                                    <div className="w-240px">
                                        <select className="form-select form-select-solid" data-control="select2" data-hide-search="true"
                                            data-placeholder="Status" data-kt-ecommerce-order-filter="status">
                                            <option value="all">All Invoices</option>
                                            <option value="Completed">SUCCESS</option>
                                            <option value="In Transit">PENDING</option>
                                            <option value="Pending">PARTIAL PAID</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                    <div className="d-flex align-items-center position-relative my-1">
                                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                                    transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                                <path
                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                    fill="currentColor" />
                                            </svg>
                                        </span>
                                        <input type="text" data-kt-ecommerce-order-filter="search"
                                            className="form-control form-control-solid w-250px ps-14" placeholder="Search Invoice" />
                                    </div>

                                    <div id="kt_ecommerce_report_returns_export" className="d-none"></div>
                                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                        <input className="form-control form-control-solid w-100 mw-250px" placeholder="Pick date range"
                                            id="kt_ecommerce_report_returns_daterangepicker" />
                                        <button type="button" className="btn btn-light-primary" data-kt-menu-trigger="click"
                                            data-kt-menu-placement="bottom-end">
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1"
                                                        transform="rotate(90 12.75 4.25)" fill="currentColor" />
                                                    <path
                                                        d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </span>
                                        </button>
                                        <div id="kt_ecommerce_report_returns_export_menu"
                                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4"
                                            data-kt-menu="true">
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="copy">Copy to clipboard
                                                </a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="excel">Export as Excel

                                                </a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="csv">Export as CSV

                                                </a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="pdf">Export as PDF
                                                </a>
                                            </div>
                                        </div>
                                        <div id="kt_ecommerce_report_shipping_export" className="d-none"></div>
                                        <button type="button" className="btn btn-light-primary" data-kt-menu-trigger="click"
                                            data-kt-menu-placement="bottom-end">
                                            <span className="svg-icon svg-icon-2">
                                                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1"
                                                        transform="rotate(90 12.75 4.25)" fill="currentColor" />
                                                    <path
                                                        d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                                                        fill="currentColor" />
                                                    <path opacity="0.3"
                                                        d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                                                        fill="currentColor" />
                                                </svg> */}

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                                                </svg>
                                            </span>
                                        </button>
                                        <div id="transaction_report_share_menu"
                                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4"
                                            data-kt-menu="true">
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="copy">Copy to clipboard

                                                </a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="excel">Export as Excel

                                                </a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="csv">Export as CSV

                                                </a>
                                            </div>
                                            <div className="menu-item px-3">
                                                <a href="#" className="menu-link px-3" data-kt-ecommerce-export="pdf">Export as PDF

                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0 ">

                                    <LoadingDataTables />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <TablesWidget11 className='mb-5 mb-xl-8' /> */}
        </>
    )
}

export { InvoiceList }