import axios from 'axios';
import { Industry } from './_models';

const username = process.env.REACT_APP_BASIC_AUTH_USERNAME || 'admin';
const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD || 'admin';


export const getIndustryList = async (): Promise<Industry[]> => {
  const response = await axios.get('https://api.merchant.payplatter.in/industry/list', {
    auth: {
      username: username,
      password: password,
    },
  });
  
  if (response.data.success) {
    return response.data.data;
  }
  throw new Error(response.data.message || 'Failed to fetch industries');
};