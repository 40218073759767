import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

// Hardcoded industry, segment, and service options for demonstration
const hardcodedIndustryOptions = [
  { value: 1, label: 'Industry A' },
  { value: 2, label: 'Industry B' },
  { value: 3, label: 'Industry C' },
  { value: 4, label: 'Industry D' },
];

// Example segments associated with industries
const hardcodedSegmentOptions: Record<number, { value: number; label: string }[]> = {
  1: [{ value: 101, label: 'Segment A1' }, { value: 102, label: 'Segment A2' }],
  2: [{ value: 201, label: 'Segment B1' }, { value: 202, label: 'Segment B2' }],
  3: [{ value: 301, label: 'Segment C1' }, { value: 302, label: 'Segment C2' }],
  4: [{ value: 401, label: 'Segment D1' }, { value: 402, label: 'Segment D2' }],
};

// Example services associated with segments
const hardcodedServiceOptions: Record<number, { value: number; label: string }[]> = {
  101: [{ value: 1001, label: 'Service A1-1' }, { value: 1002, label: 'Service A1-2' }],
  102: [{ value: 1003, label: 'Service A2-1' }, { value: 1004, label: 'Service A2-2' }],
  201: [{ value: 2001, label: 'Service B1-1' }, { value: 2002, label: 'Service B1-2' }],
  202: [{ value: 2003, label: 'Service B2-1' }, { value: 2004, label: 'Service B2-2' }],
};

interface CreatePlanModalProps {
  show: boolean;
  handleClose: () => void;
  addPlan: (plan: {
    planId: number;
    planName: string;
    industryType: string;
    segmentName: string;
    serviceName: string;
    description: string;
    isActive: string;
  }) => void;
}

const CreatePlanModal: FC<CreatePlanModalProps> = ({ show, handleClose, addPlan }) => {
  const [formData, setFormData] = useState({
    industryType: '',
    segmentId: '',
    serviceId: '',
    planName: '',
    description: '',
    isActive: 'Y',
  });
  const [availableSegments, setAvailableSegments] = useState<{ value: number; label: string }[]>([]);
  const [availableServices, setAvailableServices] = useState<{ value: number; label: string }[]>([]);

  // Update available segments when the industry changes
  useEffect(() => {
    const selectedIndustrySegments = hardcodedSegmentOptions[Number(formData.industryType)] || [];
    setAvailableSegments(selectedIndustrySegments);
    setFormData((prevData) => ({
      ...prevData,
      segmentId: '',
      serviceId: '',
    }));
    setAvailableServices([]);
  }, [formData.industryType]);

  // Update available services when the segment changes
  useEffect(() => {
    const selectedSegmentServices = hardcodedServiceOptions[Number(formData.segmentId)] || [];
    setAvailableServices(selectedSegmentServices);
    setFormData((prevData) => ({
      ...prevData,
      serviceId: '',
    }));
  }, [formData.segmentId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPlan = {
      planId: Math.floor(Math.random() * 10000), // Generate a random planId
      planName: selectedPlanName,
      industryType: hardcodedIndustryOptions.find((option) => option.value.toString() === formData.industryType)?.label || '',
      segmentName: availableSegments.find((option) => option.value.toString() === formData.segmentId)?.label || '',
      serviceName: availableServices.find((option) => option.value.toString() === formData.serviceId)?.label || '',
      description: formData.description,
      isActive: formData.isActive,
    };
    addPlan(newPlan);
    handleClose();
  };

  // for pricing cards modal
  const [selectedPlan, setSelectedPlan] = useState<string>(''); // Track selected plan
  const [expanded, setExpanded] = useState<boolean>(false);
  const [customFeatures, setCustomFeatures] = useState<boolean[]>(
    new Array(20).fill(false).map((_, index) => index < 3) // First 3 features are pre-selected
  );
  const [isHovered, setIsHovered] = useState<number | null>(null); // State to track hovered card
  const [showModal, setShowModal] = useState<boolean>(false); // Modal visibility state
  const [selectedPlanName, setSelectedPlanName] = useState<string>(''); // Store selected plan name to display in text field

  const handlePlanSelect = (plan: string) => {
    setSelectedPlan(plan);
  };

  const handleContinueWithSelected = () => {
    setSelectedPlanName(selectedPlan); // Set the selected plan name
    setShowModal(false); // Close modal
  };

  const handleFeatureToggle = (index: number) => {
    const updatedFeatures = [...customFeatures];
    updatedFeatures[index] = !updatedFeatures[index];
    setCustomFeatures(updatedFeatures);
  };

  const calculateCustomPrice = () => {
    const basePrice = 3000; // Base price for the custom plan
    const additionalFeatures = customFeatures.filter(feature => feature).length - 3; // Subtract 3 mandatory features
    return basePrice + additionalFeatures * 1000;
  };

  // Features object
  const featuresList = [
    { title: "Streamlined Payment Processing", description: "Accept payments quickly and easily with our user-friendly interface." },
    { title: "Multi-Channel Support", description: "Integrate payments across various platforms including web, mobile, and in-store." },
    { title: "Real-Time Analytics", description: "Get insights into sales trends and customer behavior with real-time data." },
    { title: "Automated Billing", description: "Automate your billing process to save time and reduce errors." },
    { title: "Fraud Detection", description: "Advanced algorithms to detect and prevent fraudulent transactions." },
    { title: "Customer Management", description: "Manage customer profiles, preferences, and purchase history." },
    { title: "Integration with Accounting Software", description: "Seamlessly integrate with popular accounting software for easy financial management." },
    { title: "Mobile Payments", description: "Allow customers to pay using their mobile devices for convenience." },
    { title: "Subscription Management", description: "Manage recurring payments effortlessly for subscription-based businesses." },
    { title: "Customizable Payment Pages", description: "Create and customize payment pages that match your brand." },
    { title: "24/7 Customer Support", description: "Get help whenever you need it with our dedicated support team." },
    { title: "Secure Transactions", description: "Ensure every transaction is safe and secure with top-notch encryption." },
    { title: "Flexible Payment Options", description: "Support for credit cards, debit cards, e-wallets, and more." },
    { title: "Reporting and Insights", description: "Generate reports to track performance and make informed business decisions." },
    { title: "User-Friendly Dashboard", description: "Manage everything from a simple and intuitive dashboard." },
    { title: "Loyalty Program Integration", description: "Integrate with loyalty programs to reward your customers." },
    { title: "Multi-Currency Support", description: "Accept payments in various currencies to cater to a global audience." },
    { title: "Custom API Solutions", description: "Get custom API solutions tailored to your business needs." },
    { title: "Email Notifications", description: "Keep your customers informed with automatic email notifications." },
    { title: "User Role Management", description: "Manage user access and roles for your team effortlessly." },
    { title: "Data Backup and Recovery", description: "Automatic data backup and recovery solutions to safeguard your data." },
    { title: "Website Integration", description: "Easily integrate payment solutions into your existing website." },
  ];

  return (
    <>
      <div className={`modal fade show ${show ? 'd-block' : 'd-none'}`} tabIndex={-1}
        style={{
          backgroundColor: `${show ? 'rgba(0, 0, 0, 0.5)' : 'transparent'}`,
        }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Plan</h5>
              <button type="button" className="btn-close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label htmlFor="industryType" className="form-label">Industry Type</label>
                  <select
                    className="form-select"
                    id="industryType"
                    name="industryType"
                    value={formData.industryType}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Industry</option>
                    {hardcodedIndustryOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="segmentId" className="form-label">Segment</label>
                  <select
                    className="form-select"
                    id="segmentId"
                    name="segmentId"
                    value={formData.segmentId}
                    onChange={handleInputChange}
                    required
                    disabled={!formData.industryType}
                  >
                    <option value="">Select Segment</option>
                    {availableSegments.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="serviceId" className="form-label">Service</label>
                  <select
                    className="form-select"
                    id="serviceId"
                    name="serviceId"
                    value={formData.serviceId}
                    onChange={handleInputChange}
                    required
                    disabled={!formData.segmentId}
                  >
                    <option value="">Select Service</option>
                    {availableServices.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <Row>
                    <Col md={4}>
                      <div><label className="form-label">Choose Plan</label></div>
                    </Col>
                    <Col md={8}>
                      <span className='text-center'>
                        <Button variant="primary w-50" style={{ backgroundColor: '#336699' }} onClick={() => setShowModal(true)} className="mb-4">
                          Choose Plan
                        </Button>
                      </span>
                    </Col>
                  </Row>
                </div>

                {/* Conditionally display selected plan field */}
                {selectedPlanName && (
                  <div className="mb-3">
                    <label className="form-label">Selected Plan</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedPlanName}
                      readOnly
                    />
                  </div>
                )}


                <div className="mb-3">
                  <label htmlFor="planDescription" className="form-label">Plan Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="planDescription"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="isActive" className="form-label">Status</label>
                  <select
                    className="form-select"
                    id="isActive"
                    name="isActive"
                    value={formData.isActive}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Y">Active</option>
                    <option value="N">Inactive</option>
                  </select>
                </div>
                <button type="submit" className="btn btn-primary  w-100" style={{ backgroundColor: '#336699' }}>Add Plan</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal with pricing cards */}
<Modal show={showModal} onHide={() => setShowModal(false)} size="xl" centered>
  <Modal.Header closeButton>
    <Modal.Title>Select Your Plan</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className='row'>
      {[
        { id: 'Basic', name: 'Basic', price: 7000, features: 10 },
        { id: 'Premium', name: 'Premium', price: 12000, features: 15 },
        { id: 'Advanced', name: 'Advanced', price: 18000, features: 20 },
        { id: 'Custom', name: 'Custom', price: calculateCustomPrice(), features: 30 }
      ].map((plan, index) => (
        <div className='col-lg-3 mb-4' key={index}>
          <div
            className={`card p-3 shadow-lg ${selectedPlan === plan.id ? 'border border-dark' : 'border border-light'}`}
            style={{
              transition: 'transform 0.4s ease, background-color 0.4s ease',
              transform: isHovered === index
                ? 'scale(1.1)'
                : isHovered !== null
                  ? 'scale(0.95)'
                  : 'scale(1)',
              background: isHovered === index ? 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)' : 'white',
              boxShadow: isHovered === index ? '0 8px 20px rgba(0, 0, 0, 0.3)' : '0 4px 10px rgba(0, 0, 0, 0.15)',
              borderWidth: selectedPlan === plan.id ? '4px' : '1px'
            }}
            onMouseEnter={() => {
              setIsHovered(index);
            }}
            onMouseLeave={() => {
              setIsHovered(null);
            }}
            onClick={() => handlePlanSelect(plan.id)}
          >
            <div className='card-body'>
              <h2 className='fw-bolder text-center mb-4' style={{ color: isHovered === index ? 'white' : 'black', fontSize: '1.5rem' }}>
                {plan.name}
              </h2>
              <h4 className='fw-bold text-center mb-4' style={{ color: isHovered === index ? 'white' : 'black' }}>
                {plan.price} INR
              </h4>

              <ul className='list-unstyled'>
                {featuresList.slice(0, 7).map((feature, featureIndex) => (
                  <li key={featureIndex} className='d-flex align-items-center justify-content-between mb-2' style={{ fontSize: '0.9rem', color: isHovered === index ? 'white' : '#333' }}>
                    <span className='fw-bold'>{feature.title}</span>
                    <span>
                      {plan.id === 'Custom' && featureIndex >= 3 ? (
                        <input
                          type="checkbox"
                          checked={customFeatures[featureIndex]}
                          onChange={() => handleFeatureToggle(featureIndex)}
                        />
                      ) : (
                        <i
                          className={`bi bi-check-circle-fill`}
                          style={{
                            color: featureIndex < plan.features ? (isHovered === index ? 'lightgreen' : 'green') : (isHovered === index ? 'lightcoral' : 'red'),
                            fontSize: '1.2rem'
                          }}
                        ></i>
                      )}
                    </span>
                  </li>
                ))}
              </ul>

              {expanded && (
                <>
                  <ul className='list-unstyled'>
                    {featuresList.slice(7).map((feature, featureIndex) => (
                      <li key={featureIndex + 7} className='d-flex align-items-center justify-content-between mb-2' style={{ fontSize: '0.9rem', color: isHovered === index ? 'white' : '#333' }}>
                        <span className='fw-bold'>{feature.title}</span>
                        <span>
                          {plan.id === 'Custom' ? (
                            <input
                              type="checkbox"
                              checked={customFeatures[featureIndex + 7]}
                              onChange={() => handleFeatureToggle(featureIndex + 7)}
                            />
                          ) : (
                            <i
                              className={`bi bi-check-circle-fill`}
                              style={{
                                color: featureIndex + 7 < plan.features ? (isHovered === index ? 'lightgreen' : 'green') : (isHovered === index ? 'lightcoral' : 'red'),
                                fontSize: '1.2rem'
                              }}
                            ></i>
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className='text-center'>
                    <button
                      className='btn btn-outline-light mt-3'
                      style={{
                        borderRadius: '25px',
                        borderColor: isHovered === index ? 'white' : '#ccc',
                        color: isHovered === index ? 'white' : 'black',
                        backgroundColor: isHovered === index ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpanded(false);
                      }}
                    >
                      See Less
                    </button>
                  </div>
                </>
              )}

              {!expanded && (
                <div className='text-center'>
                  <button
                    className='btn btn-outline-light'
                    style={{
                      borderRadius: '25px',
                      borderColor: isHovered === index ? 'white' : '#ddd',
                      color: isHovered === index ? 'white' : '#777',
                      backgroundColor: isHovered === index ? 'rgba(255, 255, 255, 0.2)' : '#f7f7f7',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(true);
                    }}
                  >
                    Read More
                  </button>
                </div>
              )}

              <button
                className='btn get-started-button w-100 mt-4'
                style={{
                  borderRadius: '25px',
                  backgroundColor: isHovered === index ? 'white' : 'transparent',
                  color: isHovered === index ? '#004187' : 'black',
                  fontWeight: 'bold',
                  border: '2px solid',
                  borderColor: isHovered === index ? '#004187' : 'black',
                  overflow: 'hidden',
                  position: 'relative',
                  width: '100%',
                  maxWidth: '250px',
                  margin: '0 auto'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlanSelect(plan.id);
                }}
              >
                <span className="button-text">
                  Get&nbsp;Started
                </span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Close
    </Button>
    <Button variant="primary" style={{ backgroundColor: '#336699' }} onClick={handleContinueWithSelected} disabled={!selectedPlan}>
      Continue with Selected
    </Button>
  </Modal.Footer>
</Modal>


    </>
  );
};

export { CreatePlanModal };
