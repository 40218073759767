import axios, { AxiosResponse } from 'axios'
import { IndustriesResponse, PartnersResponse, SegmentListByIndustry, SegmentListByIndustryResponse } from './_models'

const API_URL = 'https://api.merchant.payplatter.in'
// process.env.DEXPERT_MERCHANT_APP_API_URL

const GET_PARTNER_LIST = `${API_URL}/partner/list`
const GET_INDUSTRY_LIST = `${API_URL}/industry/list`
const GET_SEGMENT_LIST_BY_INDUSTRY = `${API_URL}/segment/listByIndustry/`

// const CONFIG =  {
//     headers: {
//       "Authorization": "Basic bWVyY2hhbnQ6bWVyY2hhbnQ=",
//     }
//   }
// const GET_USERS_URL = `${API_URL}/users/query`

// Fetch Basic Auth credentials from environment variables
const username = process.env.REACT_APP_BASIC_AUTH_USERNAME || 'admin';
const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD || 'admin';

if (!username || !password) {
  throw new Error('Basic authentication credentials are missing.');
}

const authConfig = {
    auth: {
      username,
      password,
    },
  };
  

const getPartnersList = (): Promise<PartnersResponse> => {
    // console.log(GET_PARTNER_LIST)
    return axios
        .get(`${GET_PARTNER_LIST}`, authConfig)
        .then((d: AxiosResponse<PartnersResponse>) => d.data)
}

const getIndustryList = (): Promise<IndustriesResponse> => {
    return axios
        .get(`${GET_INDUSTRY_LIST}`, authConfig)
        .then((d: AxiosResponse<IndustriesResponse>) => d.data)
}
const getSegmentListByIndustry = (ID: number | string): Promise<SegmentListByIndustryResponse> => {
    return axios
        .get(`${GET_SEGMENT_LIST_BY_INDUSTRY + ID}`, authConfig)
        .then((d: AxiosResponse<SegmentListByIndustryResponse>) => d.data)

}
export { getPartnersList, getIndustryList,getSegmentListByIndustry };