import React, { FC, useState } from 'react';

interface CreateIndustryModalProps {
  show: boolean;
  handleClose: () => void;
  addIndustry: (industry: {
    id: number;
    name: string;
    descrition: string;
    isActive: string;
  }) => void;
}

const CreateIndustryModal: FC<CreateIndustryModalProps> = ({ show, handleClose, addIndustry }) => {
  const [formData, setFormData] = useState({
    name: '',
    descrition: '',
    isActive: 'Y',
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newIndustry = {
      id: Math.floor(Math.random() * 10000), // Auto-generate a random ID for the industry
      name: formData.name,
      descrition: formData.descrition,
      isActive: formData.isActive,
    };
    addIndustry(newIndustry);
    handleClose();
  };

  return (
    <div
      className={`modal fade show ${show ? 'd-block' : 'd-none'}`}
      tabIndex={-1}
      style={{
        backgroundColor: `${show ? 'rgba(0, 0, 0, 0.5)' : 'transparent'}`,
      }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Industry</h5>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label htmlFor="industryName" className="form-label">
                  Industry Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="industryName"
                  name="name"
                  placeholder="Enter industry name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="industryDescription" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="industryDescription"
                  name="description"
                  placeholder="Enter industry description"
                  value={formData.descrition}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="industryStatus" className="form-label">
                  Status
                </label>
                <select
                  className="form-select"
                  id="industryStatus"
                  name="isActive"
                  value={formData.isActive}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Y">Active</option>
                  <option value="N">Not Active</option>
                </select>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100"
                style={{ backgroundColor: '#336699' }}
              >
                Add Industry
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CreateIndustryModal };
