import React from 'react';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import { MenuItem, MenuGroup } from './types'; // Import the defined interfaces

interface SidebarMenuMainProps {
  menus: MenuGroup[]; // Receive the menus as a prop
}

const SidebarMenuMain: React.FC<SidebarMenuMainProps> = ({ menus }) => {

  return (
    <>
      {menus.map((menuGroup: MenuGroup, menuGroupIndex: number) => (
        <div className="menu-item" key={menuGroupIndex}>
          <div className="menu-content pt-8 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              {menuGroup.name}
            </span>
            {menuGroup.menu.map((menuItem: MenuItem, menuItemIndex: number) => (
              <React.Fragment key={menuItemIndex}>
                {menuItem.submenu && menuItem.submenu.length > 0 ? (
                  <SidebarMenuItemWithSub
                    to={menuItem.route}
                    title={menuItem.name}
                    icon={`/media/icons/duotune/${menuItem.icon}.svg`}
                    fontIcon="bi-app-indicator"
                  >
                    {menuItem.submenu.map((submenuItem: MenuItem, subIndex: number) => (
                      <SidebarMenuItem
                        to={submenuItem.route}
                        title={submenuItem.name}
                        hasBullet={true}
                        key={subIndex}
                      />
                    ))}
                  </SidebarMenuItemWithSub>
                ) : (
                  <SidebarMenuItem
                    to={menuItem.route}
                    title={menuItem.name}
                    icon={`/media/icons/duotune/${menuItem.icon}.svg`}
                    fontIcon="bi-app-indicator"
                    hasBullet={false}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default SidebarMenuMain;
