import React, { FC, useState, useEffect } from 'react';

// Hardcoded industry and segment options for demonstration
const hardcodedIndustryOptions = [
  { value: 1, label: 'Industry A' },
  { value: 2, label: 'Industry B' },
  { value: 3, label: 'Industry C' },
  { value: 4, label: 'Industry D' },
];

// Example segments associated with industries
const hardcodedSegmentOptions: Record<number, { value: number; label: string }[]> = {
  1: [{ value: 101, label: 'Segment A1' }, { value: 102, label: 'Segment A2' }],
  2: [{ value: 201, label: 'Segment B1' }, { value: 202, label: 'Segment B2' }],
  3: [{ value: 301, label: 'Segment C1' }, { value: 302, label: 'Segment C2' }],
  4: [{ value: 401, label: 'Segment D1' }, { value: 402, label: 'Segment D2' }],
};

interface CreateServiceModalProps {
  show: boolean;
  handleClose: () => void;
  addService: (service: {
    serviceId: number;
    serviceName: string;
    industryType: string;
    segmentName: string;
    description: string;
    isActive: string;
  }) => void;
}

const CreateServiceModal: FC<CreateServiceModalProps> = ({ show, handleClose, addService }) => {
  const [formData, setFormData] = useState({
    industryType: '',
    segmentId: '',
    serviceName: '',
    description: '',
    isActive: 'Y',
  });
  const [availableSegments, setAvailableSegments] = useState<{ value: number; label: string }[]>([]);

  // Update available segments when the industry changes
  useEffect(() => {
    const selectedIndustrySegments = hardcodedSegmentOptions[Number(formData.industryType)] || [];
    setAvailableSegments(selectedIndustrySegments);
    setFormData((prevData) => ({
      ...prevData,
      segmentId: '',
    }));
  }, [formData.industryType]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newService = {
      serviceId: Math.floor(Math.random() * 10000), // Generate a random serviceId
      serviceName: formData.serviceName,
      industryType: hardcodedIndustryOptions.find(option => option.value.toString() === formData.industryType)?.label || '',
      segmentName: availableSegments.find(option => option.value.toString() === formData.segmentId)?.label || '',
      description: formData.description,
      isActive: formData.isActive,
    };
    addService(newService);
    handleClose();
  };

  return (
    <div className={`modal fade show ${show ? 'd-block' : 'd-none'}`} tabIndex={-1}
      style={{
        backgroundColor: `${show ? 'rgba(0, 0, 0, 0.5)' : 'transparent'}`,
      }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Service</h5>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label htmlFor="industryType" className="form-label">Industry Type</label>
                <select
                  className="form-select"
                  id="industryType"
                  name="industryType"
                  value={formData.industryType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Industry</option>
                  {hardcodedIndustryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="segmentId" className="form-label">Segment</label>
                <select
                  className="form-select"
                  id="segmentId"
                  name="segmentId"
                  value={formData.segmentId}
                  onChange={handleInputChange}
                  required
                  disabled={!formData.industryType}
                >
                  <option value="">Select Segment</option>
                  {availableSegments.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="serviceName" className="form-label">Service Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="serviceName"
                  name="serviceName"
                  value={formData.serviceName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="serviceDescription" className="form-label">Service Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="serviceDescription"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="isActive" className="form-label">Status</label>
                <select
                  className="form-select"
                  id="isActive"
                  name="isActive"
                  value={formData.isActive}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Y">Active</option>
                  <option value="N">Inactive</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#336699' }}>Add Service</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CreateServiceModal };
