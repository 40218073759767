import { MenuGroup } from "./_metronic/layout/components/sidebar/sidebar-menu/types";

export const menus: MenuGroup[] = [
  {
    name: 'Dashboard',
    menu: [
      {
        name: 'Dashboard',
        icon: 'general/gen001',
        route: '/dashboard',
      },
    ],
  },
  {
    name: 'Masters',
    menu: [
      {
        name: 'Industries',
        // icon: 'communication/com001',
        icon: 'finance/fin001',
        route: '/industry/list',
      },
      {
        name: 'Segments',
        icon: 'general/gen010',
        route: '/segment/list',
      },
      {
        name: 'Services',
        icon: 'abstract/abs027',
        route: '/service/list',
      },
      {
        name: 'Plans',
        icon: 'abstract/abs015',
        // icon: 'ecommerce/ecm001',
        // icon: 'ecommerce/ecm011',
        route: '/plan/list',
      },
      {
        name: 'Payment Gateways',
        // icon: 'ecommerce/ecm001',
        icon: 'finance/fin008',
        route: '/payment-gateway/list',
      }
    ],
  },
  {
    name: 'Onboarding',
    menu: [
      {
        name: 'Onboarding',
        icon: 'general/gen016',
        route: '/',
        submenu: [
          {
            name: 'New Onboarding',
            icon: 'Home',
            route: '/merchant/onboard/new',
          },
          {
            name: 'Clone Merchant',
            icon: 'Home',
            route: '/merchant/onboard/clone',
          },
        ],
      },
      {
        name: 'Merchants',
        icon: 'communication/com014',
        route: '/merchant/view',
        submenu: [
          {
            name: 'Our Merchants',
            icon: 'Home',
            route: '/merchant/view',
          },
        ],
      },
      // Add other menu items for Onboarding...
    ],
  },
];


// export const menus = [
//   {
//     name: 'Dashboard',
//     menu: [
//       {
//         name: 'Dashboard',
//         icon: 'Home',
//         route: '/dashboard'
//       },
//     ],
//   },
//   {
//     name: 'Masters',
//     menu: [
//       {
//         name: 'Industries',
//         icon: 'Home',
//         route: '/merchant/onboard/new',
//       },
//       {
//         name: 'Segments',
//         icon: 'Home',
//         route: '/merchant/onboard/clone',
//       },
//       {
//         name: 'Services',
//         icon: 'Home',
//         route: '/merchant/onboard/clone',
//       },
//       {
//         name: 'Plans',
//         icon: 'Home',
//         route: '/merchant/onboard/clone',
//       },
//       {
//         name: 'Payment Gateways',
//         icon: 'Home',
//         route: '/merchant/onboard/clone',
//       }
//     ],
//   },
//   {
//     name: 'Onboarding',
//     menu: [
//       {
//         name: 'Onboarding',
//         icon: 'Home',
//         route: '/',
//         submenu: [
//           {
//             name: 'New',
//             icon: 'Home',
//             route: '/merchant/onboard/new',
//           },
//           {
//             name: 'Clone',
//             icon: 'Home',
//             route: '/merchant/onboard/clone',
//           }
//         ],
//       },
//       {
//         name: 'Merchants',
//         icon: 'Home',
//         route: '/merchant/view',
//         submenu: [,
//           {
//             name: 'Our Merchants',
//             icon: 'Home',
//             route: '/merchant/view',
//           }
//         ]
//       },
//     ]
//   },
// ]


// [
//   {
//     name: 'Dashboard',
//     menu: [
//       {
//         name: 'Dashboard',
//         icon: 'Home',
//         route: '/'
//       },
//     ],
//   },
//   {
//     name: 'Masters',
//     menu: [
//       {
//         name: 'Customer',
//         icon: 'Home',
//         route: '/',
//       },
//       {
//         name: 'Vendors',
//         icon: 'Home',
//         route: '/',
//         submenu: [
//           {
//             name: 'Invoices',
//             icon: 'Home',
//             route: '/invoices/invoice-list',
//           },
//           {
//             name: 'Outstanding',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'Receipts',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'Reports',
//             icon: 'Home',
//             route: '/home',
//           }
//         ],
//       },
//       {
//         name: 'Facilities',
//         icon: 'Home',
//         route: '/',
//       }
//     ]
//   },
//   {
//     name: 'Collection',
//     menu: [{
//       name: 'Collection',
//       icon: 'Home',
//       route: '/home',
//       submenu: [
//         {
//           name: 'Invoices',
//           icon: 'Home',
//           route: '/invoices/invoice-list',
//         },
//         {
//           name: 'Outstanding',
//           icon: 'Home',
//           route: '/home',
//         },
//         {
//           name: 'Receipts',
//           icon: 'Home',
//           route: '/home',
//         },
//         {
//           name: 'Reports',
//           icon: 'Home',
//           route: '/home',
//         }
//       ]
//     }]
//   }
// ]
// export const menus = [
//   {
//     name: 'Segment Name',
//     menu: [
//       {
//         name: 'Dashboard',
//         icon: 'Home',
//         route: '/',
//         submenu: [
//           {
//             name: 'Key Statistics',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'Business Context 1',
//             icon: 'Home',
//             route: '/home',
//           }
//         ]
//       },
//       {
//         name: 'Customer',
//         icon: 'Home',
//         route: '/home',
//         submenu: [
//           {
//             name: 'Add New',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'List',
//             icon: 'Home',
//             route: '/home',
//           }
//           // Other submenu items
//         ]
//       },
//       {
//         name: 'MyTasks',
//         icon: 'Home',
//         route: '/home',
//         submenu: [
//           {
//             name: 'Calender',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'Application 1',
//             icon: 'Home',
//             route: '/home',
//           }
//         ]
//       },
//       {
//         name: 'Collection',
//         icon: 'Home',
//         route: '/home',
//         submenu: [
//           {
//             name: 'Invoices',
//             icon: 'Home',
//             route: '/invoices/invoice-list',
//           },
//           {
//             name: 'Outstanding',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'Receipts',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'Reports',
//             icon: 'Home',
//             route: '/home',
//           }
//         ]
//       },
//       {
//         name: 'Report',
//         icon: 'Home',
//         route: '/home',
//         submenu: [
//           {
//             name: 'Application 1',
//             icon: 'Home',
//             route: '/home',
//           },
//           {
//             name: 'Application 2',
//             icon: 'Home',
//             route: '/home',
//           }
//           // Other submenu items
//         ]
//       },
//       // Other main menu items
//     ]
//   },
//   // {
//   //   name: 'INVOICES',
//   //   menu: [
//   //     {
//   //       name: 'Create',
//   //       icon: 'Home',
//   //       route: '/home',
//   //       submenu: [
//   //         {
//   //           name: 'Basic',
//   //           icon: 'Home',
//   //           route: '/home',
//   //         },
//   //         {
//   //           name: 'Custom',
//   //           icon: 'Home',
//   //           route: '/home',
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       name: 'View Invoices',
//   //       icon: 'Home',
//   //       route: '/home',
//   //       submenu: [
//   //         {
//   //           name: 'Basic',
//   //           icon: 'Home',
//   //           route: '/home',
//   //         },
//   //         {
//   //           name: 'Custom',
//   //           icon: 'Home',
//   //           route: '/home',
//   //         }
//   //       ]
//   //     }
//   //     // Other main menu items
//   //   ]
//   // }
// ];