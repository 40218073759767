import React, { FC, useEffect, useState } from 'react';
import LoadingDataTables from '../../../../_metronic/partials/widgets/loader/LoadingDataTables';
import { CreatePlanModal } from './CreatePlanModal'; // The modal for adding plans
import { KTSVG } from '../../../../_metronic/helpers';


const PaymentList: FC = () => {
 
  return (
    <>
      <div>
        Comming Soon
      </div>
    </>
  );
};

export { PaymentList };
